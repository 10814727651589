/**
 * Direttiva che, utilizzando la libreria esterna css2pdf (https://github.com/Xportability/css-to-pdf) genera un file (di default è un .pdf, ma è possibile anche l'utilizzo di altri formati) incorporando tutto ciò che c'è nell'elemento del DOM avente uno specifico #id
 */

// Libreria JS esterna
declare let xepOnline: any;

export interface IFileGeneratorDirectiveScope extends ng.IScope {
    elementToPrint: any;
    embedLocalImages: any;
    pageWidth: any;
    pageHeight: any;
    pageMargin: any;
    pageMarginTop: any;
    pageMarginRight: any;
    pageMarginBottom: any;
    pageMarginLeft: any;
    filename: any;
    mimeType: any;
    resolution: any;
    render: any;
}

angular.module('app').directive('fileGenerator', ($window, $timeout, $state, blockUI) => {
    return {
        restrict: 'A',
        scope: {
            elementToPrint: '@', // id dell'elemento nel DOM che dovrà essere incluso nel file
            pageWidth: '@', // larghezza della pagina
            pageHeight: '@', // altezza della pagina
            pageMargin: '@', // magini della pagina
            pageMarginTop: '@', // margine superiore
            pageMarginRight: '@', // margine destro
            pageMarginBottom: '@', // margine inferiore
            pageMarginLeft: '@', // margine sinistro
            filename: '@', // nome del file
            mimeType: '@', // estensione del file
            resolution: '@', // risoluzione
            embedLocalImages: '@', // permette l'inclusione delle immagini nel file (di default è false)
            render: '@' // azione dopo la generazione del file, quindi se scaricarlo, aprirlo in una nuova finestra etc
        },
        link: ($scope: IFileGeneratorDirectiveScope, element: JQuery, attrs: ng.IAttributes) => {
            element.bind('click', () => {
                // Genero il file
                return xepOnline.Formatter.Format($scope.elementToPrint, {
                    embedLocalImages: $scope.embedLocalImages ? $scope.embedLocalImages : 'false',
                    pageWidth: $scope.pageWidth ? $scope.pageWidth : '8.5in',
                    pageHeight: $scope.pageHeight ? $scope.pageHeight : '11in',
                    pageMargin: $scope.pageMargin,
                    pageMarginTop: $scope.pageMarginTop,
                    pageMarginRight: $scope.pageMarginRight,
                    pageMarginBottom: $scope.pageMarginBottom,
                    pageMarginLeft: $scope.pageMarginLeft,
                    filename: $scope.filename ? $scope.filename : 'document',
                    mimeType: $scope.mimeType ? $scope.mimeType : 'application/pdf',
                    resolution: $scope.resolution ? $scope.resolution : '120',
                    render: $scope.render ? $scope.render : 'download'
                });
            });
        }
    };
});